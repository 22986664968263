import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Button } from 'reactstrap';
import Select from 'react-select';
import Promise from 'bluebird';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import './override-bootstrap-table.css';

import Organization from '../../api/Organization';
import ReportTemplate from '../../api/ReportTemplate';
import Team from '../../api/Team';
import NotificationSystem from 'react-notification-system';

import ReportTemplateLayout from './ReportTemplateLayout';

import svgLoader from '../../../theme/img/loader.svg';
import './style.css';

class EditReportTemplate extends Component {
    constructor(props) {
        super(props);
        this.notificationSystem = React.createRef();
        this.state = {
            loading: false,
            ReportTemplate: null,
            users: [],
            selectedUserToAdd: '',
            usersInOrganization: [],
            teams: [],
            selectedTeams: [],
        };
        this.getTeams = this.getTeams.bind(this);
        this.getUserInOrganization = this.getUserInOrganization.bind(this);
        this.getOrganization = this.getOrganization.bind(this);
        this.getReportTemplate = this.getReportTemplate.bind(this);
        this.onChangeSelectedTeams = this.onChangeSelectedTeams.bind(this);
        this.onChangeSelectedUser = this.onChangeSelectedUser.bind(this);
        this.onChangeUserRole = this.onChangeUserRole.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    async getOrganization() {
        const organization = await Organization.getById(this.props.match.params.organization_id);
        await this.setState({ organization });
    }

    async getUserInOrganization() {
        const usersInOrganization = await Organization.getAllUsers(this.props.match.params.organization_id);
        const usersInOrganizationOptions = usersInOrganization.map((u) => {
            const option = {
                label: u.email,
                value: u.id,
            };
            return option;
        });
        await this.setState({ usersInOrganization: usersInOrganizationOptions });
    }

    async getTeams() {
        const teams = await Team.get(this.props.match.params.organization_id);
        const teamsOptions = teams.map((t) => {
            const option = {
                label: t.title,
                value: t.id,
            };
            return option;
        });
        this.setState({ teams: teamsOptions });
    }

    getReportTemplateId() {
        const reportTemplateId =
            this.props.match.params.report_user_template_id ||
            this.props.match.params.report_custom_template_id ||
            this.props.match.params.report_note_export_template_id;
        return reportTemplateId;
    }

    getReportType() {
        if (this.props.match.params.report_user_template_id) {
            return 'reportUserTemplate';
        }
        if (this.props.match.params.report_user_template_id) {
            return 'reportCustomTemplate';
        }
        return 'reportNoteExportTemplate';
    }

    async getReportTemplate() {
        const reportTemplate = await ReportTemplate[this.getReportType()].getById(
            this.props.match.params.organization_id,
            this.getReportTemplateId(),
        );
        const { users } = reportTemplate;
        const selectedTeams = reportTemplate.teams.map((t) => {
            const option = {
                label: t.title,
                value: t.id,
            };
            return option;
        });
        this.setState({
            reportTemplate,
            users,
            selectedTeams,
        });
    }

    async onChangeSelectedTeams(options) {
        const { selectedTeams } = this.state;
        const optionsWithDefault = options ? options : [];
        const teamsToRemove = selectedTeams
            .filter((t) => {
                return !optionsWithDefault.find((o) => o.value === t.value);
            })
            .map((t) => t.value);
        const teamsToAdd = optionsWithDefault
            .filter((o) => {
                return !selectedTeams.find((t) => t.value === o.value);
            })
            .map((t) => t.value);
        await this.setState({ selectedTeams: optionsWithDefault });
        try {
            if (teamsToRemove.length) {
                await Promise.each(teamsToRemove, async (teamId) => {
                    await ReportTemplate[this.getReportType()].removeTeam(
                        this.props.match.params.organization_id,
                        this.getReportTemplateId(),
                        teamId,
                    );
                });
            }
            if (teamsToAdd.length) {
                await Promise.each(teamsToAdd, async (teamId) => {
                    await ReportTemplate[this.getReportType()].addTeam(
                        this.props.match.params.organization_id,
                        this.getReportTemplateId(),
                        teamId,
                    );
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    async onChangeSelectedUser(option) {
        await this.setState({ selectedUserToAdd: option, loading: true });
        try {
            await ReportTemplate[this.getReportType()].addUser(
                this.props.match.params.organization_id,
                this.getReportTemplateId(),
                option.value,
            );
            await this.getReportTemplate();
            await this.setState({ selectedUserToAdd: '' });
        } catch (e) {
            console.error(e);
        }
        await this.setState({ loading: false });
    }

    async onChangeUserRole(e) {
        const { name: userId, value: role } = e.target;
        await this.setState({
            loading: true,
        });
        try {
            await ReportTemplate[this.getReportType()].updateUser(
                this.props.match.params.organization_id,
                this.getReportTemplateId(),
                userId,
                {
                    role,
                },
            );
            await this.getReportTemplate();
        } catch (e) {
            console.error(e);
        }
        await this.setState({
            loading: false,
        });
    }

    async removeUser(userId) {
        await this.setState({
            loading: true,
        });
        try {
            await ReportTemplate[this.getReportType()].updateUser(
                this.props.match.params.organization_id,
                this.getReportTemplateId(),
                userId,
                {
                    is_deleted: true,
                },
            );
            await this.getReportTemplate();
        } catch (e) {
            console.error(e);
        }
        await this.setState({
            loading: false,
        });
    }

    async getAllData() {
        await this.setState({ loading: true });

        await Promise.all([
            this.getOrganization(),
            this.getReportTemplate(),
            this.getTeams(),
            this.getUserInOrganization(),
        ]);
        await this.setState({ loading: false });
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        this.getAllData();
    }

    render() {
        const { t } = this.props;
        const {
            loading,
            reportTemplate,
            organization,
            users,
            teams,
            selectedTeams,
            usersInOrganization,
            selectedUserToAdd,
        } = this.state;

        const oneUserInTemplate = users.length === 1;

        return (
            <ReportTemplateLayout
                organization={organization}
                reportTemplateTitle={reportTemplate ? reportTemplate.title : null}
            >
                <div className="loading-container">
                    <h5 className="form-header">{reportTemplate ? reportTemplate.title : ''}</h5>
                    <div className="form-desc">{t('EditReportTemplate-sentence')}</div>
                    {loading === true && (
                        <div className="loading d-flex justify-content-center">
                            <img src={svgLoader} className="loading-spinner" alt="loader" />
                        </div>
                    )}

                    {reportTemplate && (
                        <p>
                            {t('EditReportTemplate-EditThisReport')}{' '}
                            <a href={`${window._env_.REACT_APP_WEBAPP_URL}/report-templates`}>
                                {t('EditReportTemplate-EditThisReportLink')}
                            </a>
                        </p>
                    )}

                    <div>
                        <fieldset className="form-group">
                            <legend>
                                <span>{t('EditReportTemplate-UsersTitle')}</span>
                            </legend>

                            <p>{t('EditReportTemplate-UsersDescription')}</p>
                            <div class="alert alert-primary">
                                <b>{t('EditReportTemplate-RightsOfRoles')}</b> <br />-{' '}
                                <b>{t('EditReportTemplate-RightsOfRoles-AdminKey')}</b>{' '}
                                {t('EditReportTemplate-RightsOfRoles-AdminDesc')}
                                <br />- <b>{t('EditReportTemplate-RightsOfRoles-EditorKey')}</b>{' '}
                                {t('EditReportTemplate-RightsOfRoles-EditorDesc')} <br />-{' '}
                                <b>{t('EditReportTemplate-RightsOfRoles-UserKey')}</b>{' '}
                                {t('EditReportTemplate-RightsOfRoles-UserDesc')}
                            </div>

                            <div className="table-responsive">
                                <table className="table table-lightborder">
                                    <thead>
                                        <tr>
                                            <th>{t('EditReportTemplate-UserListEmailColumn')}</th>
                                            <th>{t('EditReportTemplate-RoleColumn')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user) => (
                                            <tr>
                                                <td>{user.email}</td>
                                                <td>
                                                    <select
                                                        value={user.role}
                                                        className="form-control"
                                                        name={user.id}
                                                        onChange={this.onChangeUserRole}
                                                    >
                                                        <option value="admin">
                                                            {t('EditReportTemplate-AdminRole')}
                                                        </option>
                                                        <option value="editor">
                                                            {t('EditReportTemplate-EditorRole')}
                                                        </option>
                                                        <option value="user">{t('EditReportTemplate-UserRole')}</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <Button
                                                        onClick={() => this.removeUser(user.id)}
                                                        outline
                                                        color="danger"
                                                        disabled={oneUserInTemplate}
                                                    >
                                                        {t('EditReportTemplate-DeleteButton')}
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <label className="small">{t('EditReportTemplate-AddUsersLabel')}</label>
                                    <Select
                                        name="form-field-name"
                                        value={selectedUserToAdd}
                                        onChange={this.onChangeSelectedUser}
                                        options={usersInOrganization}
                                        styles={{
                                            container: (base, state) => {
                                                base.zIndex = '4';
                                                return base;
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className="form-group">
                            <legend>
                                <span>{t('EditReportTemplate-TeamsTitle')}</span>
                            </legend>

                            <p>{t('EditReportTemplate-TeamsDescription')}</p>

                            <Select
                                name="shared-teams"
                                value={selectedTeams}
                                isMulti
                                onChange={this.onChangeSelectedTeams}
                                options={teams}
                                styles={{
                                    container: (base, state) => {
                                        base.zIndex = '3';
                                        return base;
                                    },
                                }}
                            />
                        </fieldset>
                    </div>

                    <NotificationSystem ref={this.notificationSystem} />
                </div>
            </ReportTemplateLayout>
        );
    }
}

export default translate('admin')(EditReportTemplate);
