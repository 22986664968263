import axios from 'axios';

export default {
    async getByOrganizationId(organizationId, { billingGroupIds, title }) {
        const { data } = await axios.post(
            `${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/project_templates/search`,
            {
                billing_group_ids: billingGroupIds,
                title,
            },
        );
        return data;
    },

    async getById(organizationId, formTemplateId) {
        const { data } = await axios.get(
            `${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/project_templates/${formTemplateId}`,
        );
        return data;
    },

    async addUser(organizationId, formTemplateId, userId) {
        const { data } = await axios.post(
            `${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/project_templates/${formTemplateId}/users/${userId}`,
        );
        return data;
    },

    async updateUser(organizationId, formTemplateId, userId, toUpdate) {
        const { data } = await axios.patch(
            `${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/project_templates/${formTemplateId}/users/${userId}`,
            toUpdate,
        );
        return data;
    },

    async addToTeam(organizationId, formTemplateId, teamId) {
        const { data } = await axios.post(
            `${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/project_templates/${formTemplateId}/teams/${teamId}`,
        );
        return data;
    },

    async removeFromTeam(organizationId, formTemplateId, teamId) {
        const { data } = await axios.delete(
            `${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/project_templates/${formTemplateId}/teams/${teamId}`,
        );
        return data;
    },
};
