import axios from 'axios';

const SERVER_URL = window._env_.REACT_APP_SERVER_URL;

export default {
    get() {
        return axios.get(`${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations`).then((data) => data.data);
    },

    async getById(organizationId) {
        const { data } = await axios.get(`${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations`);
        return data.find((org) => org.id === organizationId);
    },

    getTeams(organizationId) {
        return axios
            .get(`${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/teams`, {
                params: { take: 100000, skip: 0 },
            })
            .then((data) => data.data);
    },

    getProjects(organizationId) {
        return axios
            .get(`${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/projects`)
            .then((data) => data.data);
    },

    getHistory(organizationId) {
        return axios
            .get(`${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/history`)
            .then((data) => data.data);
    },

    getUsersWithStatistics(organizationId, params) {
        return axios
            .get(`${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/statistics/users`, {
                params,
            })
            .then((data) => data.data);
    },

    getSessionsCount(organizationId, params) {
        return axios
            .get(
                `${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/statistics/sessions_count`,
                {
                    params,
                },
            )
            .then((data) => data.data);
    },

    getProjectsWithStatistics(organizationId, params) {
        return axios
            .get(
                `${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/statistics/projects`,
                {
                    params,
                },
            )
            .then((data) => data.data);
    },

    async getExportApiCredentials(organizationId) {
        const { data } = await axios.get(
            `${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/export_api_credentials`,
        );
        const [userId, userPassword] = window.atob(data.api_key).split(':');
        const url = `${SERVER_URL}/v1/organizations/${organizationId}/datasets/latest.json`;
        return { userId, userPassword, url };
    },

    async getAllUsers(organizationId) {
        const { data } = await axios.get(
            `${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/users`,
            {
                params: { take: 100000, skip: 0 },
            },
        );
        return data;
    },
};
