import axios from 'axios';

export default {
    addToTeam(formTemplateId, teamId) {
        return axios
            .post(`${window._env_.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}/form_templates/${formTemplateId}`)
            .then((data) => data.data);
    },

    removeFromTeam(formTemplateId, teamId) {
        return axios
            .delete(
                `${window._env_.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}/form_templates/${formTemplateId}`,
            )
            .then((data) => data.data);
    },
    /**
     * FormTemplateOrganization
     */

    async getByOrganizationId(organizationId, { billingGroupIds, title }) {
        const { data } = await axios.post(
            `${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/form_templates/search`,
            {
                billing_group_ids: billingGroupIds,
                title,
            },
        );
        return data;
    },

    async getById(organizationId, formTemplateId) {
        const { data } = await axios.get(
            `${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/form_templates/${formTemplateId}`,
        );
        return data;
    },

    async addUser(organizationId, formTemplateId, userId) {
        const { data } = await axios.post(
            `${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/form_templates/${formTemplateId}/users/${userId}`,
        );
        return data;
    },

    async updateUser(organizationId, formTemplateId, userId, toUpdate) {
        const { data } = await axios.patch(
            `${window._env_.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/form_templates/${formTemplateId}/users/${userId}`,
            toUpdate,
        );
        return data;
    },
};
